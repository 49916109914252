@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    padding: 0;
    margin: 0;
    width: 100%;

    @media breakpointMdUp {
      width: 400px;
    }
  }
}
