@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";

@layer components {
  .container {
    display: none;

    @media breakpointMdUp {
      display: contents;
    }
  }

  .qrCode {
    border: 1px solid black;
    border-radius: borderRadiusMedium;
    width: 76px;
    padding: sm;
  }

  .qrCode--md {
    width: 141px;
    padding: md;
  }

  .qrCodeTitle {
    font-size: 0.875rem;
  }

  .qrCodeTitle--md {
    margin: sm 0;
    font-size: 1.5rem;
  }

  .qrCodeText {
    font-size: 0.625rem;
  }

  .qrCodeText--md {
    font-size: 0.875rem;

  }
}
