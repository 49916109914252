@value xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .footer {
    margin-top: xl;

    @media breakpointMdUp {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 72px;
    }
  }

  .appDownloadButton {
    display: inline-block;

    @media breakpointMdUp {
      display: none;
    }
  }
}
