@layer components {
  .container {
    position: relative;
    min-height: 24px;
    display: flex;
    align-items: center;

    div {
      width: 100%;
    }
  }

  .spinner {
    position: absolute;
    left: calc(50% - 18px);
  }
}
