@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";
@value midGrey from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
    .container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 lg;
        text-align: center;
    }

    .containerInMobile {
        padding: lg;
    }

    .heading {
        font-size: 18px;
        margin-top: md;
    }

    .wrapper {
        margin: lg 0 xl 0;
        width: 100%;
        text-align: left;
    }

    .inputsWrapper {
        display: grid;
        grid-template-columns: 110px 1fr;
    }

    .label {
        font-family: fontFamilyBody;
        font-size: 12px;
        color: midGrey;
        width: 100%;
        line-height: 1.5;
        margin-bottom: xs;
    }

    .footnote {
        font-size: 12px;
        margin-bottom: lg;
    }

    .countryCode {
        margin-bottom: 0;
    }

    .phoneNumber {
        position: relative;
        right: 1px;
        margin-bottom: 0;
    }

    .button {
        margin: 0 auto;
    }

    .buttonSpinner {
        margin-right: sm;
    }
}

@layer overrides {
    .countryCode ul {
        width: 248px;
    }

    .countryCode label, .phoneNumber label {
        display: none;
    }
}
