@value sm from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer component {
  .card {
    display: flex;
    flex-direction: column;
    min-width: var(--minWidth);
  }

  .image {
    aspect-ratio: 1 / 1;
  }

  .attributes {
    margin-top: sm;
  }
}
