@value sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value black from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .container {
    margin: lg 0;
    padding: lg;

    @media breakpointMdUp {
      margin: xl 0;
    }
  }

  .authorHyphen {
    font-size: 1.5rem;
    margin-right: sm;
  }
}

@layer overrides {
  .quote {
    font-size: 1.5rem;
    font-style: italic;
    margin: 0;
  }

  .author {
    display: inline-block;
    font-size: 1.5rem;
    margin: 0;
    color: black;

    a {
      color: black;
      transition: all 0.2s ease;

    }

    a:hover {
      opacity: 0.6;
    }
  }
}
