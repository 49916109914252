@value breakpointXsUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value sm, md, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value lightGrey2, black, red from "@depop/web-ui-kit/theme/default/colors.module.css";

@layer components {
  .section {
    padding: md;
    border-bottom: 1px solid lightGrey2;
    flex-wrap: wrap;
    text-align: center;

    @media breakpointXsUp {
        padding-bottom: xl;
    }
  }

  .fullWidthContainer {
    width: 100%;
  }

  .checkmarkIcon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 2px solid black;
    padding: 12px;
    margin-bottom: sm;
  }

  .feedbackLabel {
    margin-bottom: sm;
  }

  .starWrapper {
    margin: auto 0 sm;
  }

  .instructionWrapper {
    margin-bottom: md;
  }

  .modalContainer {
    padding: 0 xl xl;
  }

  .header {
    padding-bottom: xl;
    text-align: center;
  }

  .userDetailsWrapper {
    padding: md 0;
    text-align: center;
  }

  .avatar {
    margin-bottom: md;
  
    > div {
      background: lightGrey2;
    }
  }

  .lightTextError {
    color: red;
  }

  .label{
    display: inline-block;
    margin-bottom: sm;
    font-weight: bold;
  }

  .disclaimerWrapper {
    margin-top: 17px; /* 24px - 0.5rem (7px) set on the text area */
    margin-bottom: xl;
  }

  .error {
    text-align: center;
    margin-top: md;
  }
}
