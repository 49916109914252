@value xs, sm, md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value red from "@depop/web-ui-kit/theme/default/colors.module.css";
@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .shopSummary {
    display: flex;
    align-items: center;
    margin: sm 0 md;
    gap: md;

    @media breakpointSmUp {
      margin: lg 0;
      gap: lg;
    }
  }

  .shopInfo {
    display: flex;
    flex-direction: column;
    gap: xs;
    min-width: 0;
    flex: 1;
  }

  .username {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.125rem;

    @media breakpointSmUp {
      font-size: 1.5rem;
    }
  }

  .shopBio {
    margin: md 0 0;

    p {
      word-break: break-all;
      overflow-wrap: break-word;
    }

    a {
      text-decoration: none;
      color: red;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
    }

    @media breakpointSmUp {
      width: 312px;
      margin: md 0 0 0;
    }
  }

  .sellerName {
    margin-bottom: sm;
  }

  .followsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .messageButtonWrapper {
    display: flex;
    margin-right: sm;
    margin-left: md;
    padding: 0;

    @media breakpointSmUp {
      margin-right: 0;
    }
  }
}
