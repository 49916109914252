@value md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";

@layer components {
  .row {
    display: grid;
    grid-template-columns: 1fr;
    gap: md;
    margin-bottom: lg;

    @media breakpointSmUp {
      grid-template-columns: var(--columns);
    }

    @media breakpointMdUp {
      margin-bottom: xl;
    }
  }

  .rowReverse {
    .column:first-child {
      order: 1;
    }

    .column:last-child {
      order: 0;
    }

    @media breakpointSmUp {
      .column:first-child {
        order: 0;
      }

      .column:last-child {
        order: 1;
      }
    }
  }
}

@layer overrides {
  .column {
    img,
    video {
      width: 100%;
      margin: 0 auto;
    }
  }

  .row {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
