@value breakpointSmUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value xl from "@depop/web-ui-kit/theme/default/spacing.module.css";

@layer components {
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column-reverse;
    margin: xl 0;

    @media breakpointSmUp {
      flex-direction: row;
      margin: 72px 0;
    }
  }

  .row--flipped {
    flex-direction: column-reverse;
    
    @media breakpointSmUp {
      flex-direction: row-reverse;
    }
  }

  .content {
    width: 100%;

    @media breakpointSmUp {
      max-width: 465px;
    }
  }

  .demo {
    @media breakpointSmUp {
      max-width: 364px;
    }
  }
}

@layer overrides {
  .row {
    img,
    video {
      margin: 0;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }
}
