@value md, lg, xl from "@depop/web-ui-kit/theme/default/spacing.module.css";
@value breakpointSmUp, breakpointMdUp from "@depop/web-ui-kit/theme/default/breakpoints.module.css";
@value borderRadiusMedium from "@depop/web-ui-kit/theme/default/border.module.css";
@value fontFamilyBody from "@depop/web-ui-kit/theme/default/fonts.module.css";

@layer components {
  .body {
    p,
    h3,
    ul,
    ol {
      margin-bottom: lg;

      @media breakpointMdUp {
        margin-bottom: xl;
      }
    }

    p:empty {
      display: none; /** Fixes emtpy <p> tags which are rendered after Embedded Assets https://github.com/contentful/rich-text/issues/101#issuecomment-553924366 */
    }

    ul,
    ol {
      margin-left: md;
    }

    img,
    video {
      display: block;
      margin: 0 auto lg;
      max-width: 100%;
      overflow: hidden;
      border-radius: borderRadiusMedium;

      @media breakpointMdUp {
        margin: 0 auto xl;
      }
    }
  }

  .heading3 {
    font-family: fontFamilyBody;
    font-size: 1.125rem;

    @media breakpointMdUp {
      font-size: 1.5rem;
    }
  }
}
